<template>
  <div class="auth-view">
    <section class="h-auto flex justify-end">
      <div class="bg-white md:w-1/2 p-5 md:p-20 2xl:h-auto">
        <div>
          <Logo
            :path="logo"
            className="logo flex justify-center"
            width="100"
          ></Logo>
        </div>
        <h3 id="title" class="mt-5 text-darkBlue font-semibold text-xl">
          REGISTER
        </h3>
        <form class="mt-5" @submit.prevent="handleSubmitRegister">
          <div class="form__div">
            <input
              class="form__input"
              type="text"
              placeholder=" "
              id="firstName"
              v-model="firstName"
              required
            />
            <label for="firstName" class="form__label">First name</label>
          </div>
          <div class="form__div">
            <input
              class="form__input"
              type="text"
              placeholder=" "
              id="lastName"
              v-model="lastName"
              required
            />
            <label for="lastName" class="form__label">Last name</label>
          </div>
          <div class="form__div">
            <input
              class="form__input"
              type="email"
              id="email"
              placeholder=" "
              v-model="email"
              required
            />
            <label for="email" class="form__label">Email</label>
          </div>
          <div class="form__div">
            <input 
              class="form__input" 
              type="text" id="phoneNumber" 
              placeholder=" " 
              v-model="phoneNumber" 
              required />
            <label for="phoneNumber" class="form__label">Phone number</label>
          </div>
          <div class="form__div">
            <input
              class="form__input"
              type="password"
              placeholder=" "
              id="password"
              @click="changeShowPasswordReq()"
              @focus="changeShowPasswordReq()"
              v-model="password"
              minlength="8"
              required
            />
            <label for="password" class="form__label">Password</label>
            <div
              v-if="showPasswordReq"
              class="absolute -left-72 -top-2/3 p-4 border-2 bg-white"
            >
              <span
                class="absolute top-1/4 w-4 h-4 transform -rotate-45 border-b-2 border-r-2 z-10 bg-white"
                style="left: 98%"
              ></span>
              <p>To make your password stronger:</p>
              <ol class="list-disc list-inside m-2">
                <li>Min 8 characters</li>
                <li>Min 1 number</li>
                <li>Min 1 capital letter</li>
                <li>Min 1 special character</li>
              </ol>
            </div>
          </div>
          <div class="form__div">
            <input
              class="form__input"
              type="password"
              placeholder=" "
              id="confirm-password"
              v-model="confirmPassword"
              required
              minlength="8"
            />
            <label for="confirm-password" class="form__label"
              >Confirm password</label
            >
          </div>
          <div class="flex flex-col items-start">
            <div>
              <input
                class="ml-1"
                type="checkbox"
                v-model="acceptedTermsAndCond"
              />
              <span class="ml-2 text-sm">
                I agree with
                <a
                  href="https://www.mastermindsports.com/membership-terms"
                  target="_blank"
                  class="underline font-semibold"
                >
                  terms of service
                </a>
              </span>
            </div>

            <div>
              <input class="ml-1" type="checkbox" v-model="isAdult" />
              <span class="ml-2 text-sm"> I acknowledge that I am 18+ </span>
            </div>

            <span class="ml-2 mt-3 text-xs italic text-justify">
              We are committed to your privacy. By submitting your information,
              you give Mastermind Sports consent to contact you about our
              program by email, phone or text message. For more information,
              check out our
              <a
                href="https://www.mastermindsports.com/privacy-policy"
                target="_blank"
                class="underline font-semibold"
              >
                privacy policy
              </a>
            </span>
          </div>
          <button
            id="b-signup"
            class="text-darkBlue w-full mt-8 p-3 border-0 bg-mindaro"
            type="submit"
            :disabled="!(acceptedTermsAndCond || isAdult) || proccessingSignup"
          >
            <em v-if="proccessingSignup" class="fa fa-spinner fa-spin"></em>
            Sign Up
          </button>
        </form>
        <div class="text-center text-color m-0 mt-4">
          Already have an account? |
          <router-link :to="{ name: 'Login' }" class="underline"
            >Login</router-link
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import jwt_decode from "jwt-decode";
import Logo from "@/components/auth/Logo";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

import { formatPhoneNumber } from "@/utils/formatPhoneNumber.js";

const store = useStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();

const registerNewUser = async (data) =>
  store.dispatch("user/registerNewUser", data);

const login = async (email, password) =>
  store.dispatch("user/login", { email, password });

const setUserIsLogged = (value) => store.commit("user/setUserIsLogged", value);

const firstName = ref(null);
const lastName = ref(null);
const email = ref(null);
const phoneNumber = ref("");
const password = ref(null);
const confirmPassword = ref(null);
const registrationCode = ref("");
const isSponsorAccount = ref(false);
const profiles = ref(0);
const logo = ref(require("@/assets/icons/mm-icon.svg"));
const showPasswordReq = ref(false);
const acceptedTermsAndCond = ref(false);
const isAdult = ref(false);
const proccessingSignup = ref(false);

onMounted(() => {
  const { data } = route.query;

  if (data) {
    const decoded = jwt_decode(data);
    email.value = decoded.email;
    registrationCode.value = decoded.code;
    isSponsorAccount.value = decoded.sponsor;
    profiles.value = decoded.dependents;
  }
});

watch(password, (newPassword) => {
  const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$ %^&*-.]).{8,}$/;
  if (reg.test(newPassword)) {
    showPasswordReq.value = false;
  }
});

function changeShowPasswordReq() {
  showPasswordReq.value = true;
}

async function handleSubmitRegister() {
  if (validatePassword()) {
    proccessingSignup.value = true;

    try {
      await registerNewUser({
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        password: password.value,
        registrationCode: registrationCode.value,
        dependents: profiles.value,
        fromWeb: true,
        phoneNumber: `+1${phoneNumber.value}`
      });

      if (isSponsorAccount.value) {
        sessionStorage.setItem("parentEmail", email.value);
        return router.push({
          name: "Register players",
          query: { n: profiles.value },
        });
      }

      await login(email.value, password.value)
      setUserIsLogged(true);
      return router.push({ name: "Home" });
    } catch (error) {
      proccessingSignup.value = false;
      showError(error.message)
    }
  }

  function validatePassword() {
    if (password.value !== confirmPassword.value) {
      showError("Passwords do not match.");
      return false;
    }
    if (password.value.length < 8) {
      showError(
        "This password is too short. It must contain at least 8 characters."
      );
      return false;
    }

    const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$ %^&*-.]).{8,}$/;
    if (!reg.test(password.value)) {
      showError("Password does not satisfy the requirements.");
      return false;
    }
    return true;
  }

  function showError(errorMess) {
    toast.error(errorMess)
  } 
}

watch(phoneNumber, (newValue, oldValue) => {
  const formatted = formatPhoneNumber(newValue);
  if (newValue !== formatted) {
    phoneNumber.value = formatted;
  }
});
</script>
<style scoped>
.auth-view {
  background: url("../../assets/background-login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#b-signup:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

#b-signup {
  background-color: #edfe86;
  border: none;
  padding: 11px;
  width: 100%;
  margin-top: 2.5rem;
  cursor: pointer;
}
</style>
